import CircularProgress from '@mui/material/CircularProgress';
import { useState,useEffect } from 'react';
import { CategoryScale } from 'chart.js';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import '../Admin/adminmain.css'
import 'moment/locale/ru';
import { address } from '../settings';

Chart.register(CategoryScale);
const Adminmain = () => {
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [users,setUsers] = useState([])
    const token = Cookies.get('_auth')
    const [sum,setSum] = useState({})
    const [counter,setCounter] = useState(0)
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        let date = moment().format('YYYY-MM-DD HH:mm:ss')
        fetch(address+'/api/get/all/users', {//api
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            setUsers(result[0])
            let sum = 0
            setSum(result[3]*48)
            setKeyArray(Object.keys(result[1]))
            setValArray(Object.values(result[1]))
            setCounter(result[2])
            setLoading(false)
          }
            else alert("Неверно введённые данные")
        })
    },[])
    const str = (txt) => {
        return (<div className='info-txt'>
            {txt}
        </div>)
    }
    return (
        <div className='admin-main'>
            <div className='main-user-table'>
                <div className='main-user-table-title'>
                    <div className='main-user-table-title-text'>Таблица пользователей</div>
                </div>
                {loading?(
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                ):(
                    <div className='main-user-table-data'>
                        <table className='admin-table'>
                            <thead id='admin-thead'>
                                <tr id='ad-th'>
                                    <th className='ad-th'>Логин</th>
                                    <th className='ad-th'>e-mail</th>
                                    <th className='ad-th'>Организация</th>
                                    <th className='ad-th'>Договор</th>
                                    <th className='ad-th'>Дата создания</th>
                                    <th className='ad-th'>Статус</th>
                                </tr>
                            </thead>
                            <tbody className='ad-tbody'>
                                {users.length==0&&(
                                <tr>
                                    <td className='rs-td' colSpan='6'>Нет данных</td>
                                </tr>)}
                                {users.map((user)=>
                                    <tr key={user.id} className='tr-td'>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{user.login}</Link></td>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{user.email}</Link></td>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{user.organization}</Link></td>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{user.contract}</Link></td>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{moment(user.datetime_creation).format("DD.MM.YYYY")}</Link></td>
                                        <td className='ad-td'><Link to={`/admin/adminstat/${user.id}`}>{user.is_active?'активен':'не активен'}</Link></td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                )}    
            </div>
                <div className="admin-stat-info-content">
                    <div className='block-name'>
                        <div className='admin-stat-info-content-title-text'>Статистика за текущий месяц</div>
                    </div>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='admin-stat-info'>
                        <div className='admin-stat-info-diagram'>
                            <Bar style={{background:'white',borderRadius:10,padding:10}}
                                data={{
                                    labels: moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                                    datasets: [
                                        {
                                        data: ValArray,
                                        borderColor:'#000ffffff ',
                                        borderWidth:0.7,
                                        hoverBackgroundColor:'#006ba6',
                                        backgroundColor:'#63c7ff',
                                        },                                       
                                    ],
                                }}
                                options={{
                                    interaction: {
                                        intersect: false,
                                    },
                                    scales: {
                                        yAxes: {
                                            title:{
                                                display:!(document.documentElement.scrollWidth<500),
                                                text:'Исследования'
                                            },
                                        },
                                        xAxes: {
                                            title:{
                                                display:!(document.documentElement.scrollWidth<500),
                                                text:'Дата'
                                            },
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            labels: {
                                                display: false
                                            }
                                        },
                                    }
                                }}
                            />
                        </div>
                        <div className='admin-stat-info-data'>
                            {str("Максимальное количество исследований: "+ Math.max(...ValArray))}
                            {str("Минимальное количество исследований: "+Math.min(...ValArray))}
                            {str("Всего: "+ counter)}
                            {str("Текущая сумма за месяц: "+sum)}
                        </div>
                    </div>
                    )}
                </div>
        </div>
    );
};

export default Adminmain;