import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import React, {  useState } from 'react';
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import ftz from '../images/ftz.svg'
import ftz_en from '../images/ftz_en.svg'
import { address } from '../settings';
import { site_address } from '../settings';

import '../styles/login.css';

const Auth = () => {
    const intl = useIntl()
    const [loading,setLoading]=useState(false)
    const signIn = useSignIn()
    const navigate = useNavigate()
    const sendata = () => {
        if(document.getElementById('auth-login-btn').value==''&&document.getElementById('auth-pass-btn').value==''){alert('Заполните пустые поля');return 1}
        fetch(address+'/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
            },
            body:'grant_type=&username='+document.getElementById('auth-login-btn').value+'&password='+document.getElementById('auth-pass-btn').value+'&scope=&client_id=&client_secret='
        }).then(response=>{if(response.ok) return response.json();else return false})
        .then(result=>{
            if(result!=false){
                if(signIn(
                    {
                        token: result.access_token,
                        expiresIn:1440,
                        tokenType: "Bearer",
                        authState: {
                            role:result.role,
                            login:result.login,
                            address:result.address,
                        },
                    }
                ))result.role=='user'? window.location.assign(site_address+'/'+result.role+'/usermain'):window.location.assign(site_address+'/'+result.role+'/adminmain')
            }
            else alert(intl.formatMessage({id:'incorrect_data'}))
        })
    }
    return (
        <div className='authentificate'>
            <div className='auth-form'>
                <div className='auth-form-data'>
                    <div className='rel-div'><a rel="noreferrer" onClick={()=>navigate('/')}><ArrowCircleLeftIcon sx={{fontSize:40,color:'white'}}/></a></div>
                    <div className='auth-form-content'>
                        <div className='auth-form-title'>
                            <FormattedMessage id="auth_page_auth"/>
                        </div>
                        <div className='auth-form-form'>
                            <div className='only-input' > 
                                <div className='auth-form-input'><input type='text' id="auth-login-btn" placeholder={intl.formatMessage({id:'auth_page_login'})} onKeyDown={()=>{}}/></div>
                                <div className='auth-form-input'><input type="password" id="auth-pass-btn" placeholder={intl.formatMessage({id:'auth_page_password'})} onKeyDown={()=>{}}/></div>
                            </div>
                            {loading?(
                                <div className='div-loading'>
                                    <CircularProgress sx={{color:'white'}}/>
                                </div>
                            ):(
                                <div className='auth-form-button'><button id = 'authbtn' onClick={()=>sendata()}><FormattedMessage id="auth_page_title"/></button></div>)}
                        </div>
                    </div>  
                </div>
                <div className='auth-form-logo'>
                    <div className='auth-logo-content'>
                        <div className='auth-logo'>
                            {localStorage.getItem('locale')=='ru-RU'?(<img src={ftz} alt=''/>):(<img src={ftz_en} alt=''/>)} 
                        </div>
                        <div className='auth-text'>
                            <FormattedMessage id="auth_page_text"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Auth;