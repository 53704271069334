import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import { useState,useEffect } from 'react';
import { CategoryScale } from 'chart.js';
import { Link } from 'react-router-dom';
import {Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import './usermain.css'
import { address } from '../settings';

Chart.register(CategoryScale);

const Usermain = () => {
    const intl = useIntl()
    const token = Cookies.get('_auth')
    const [loading,setLoading] = useState(true)
    const [counter,setCounter] = useState(0)
    const [price,setPrice] = useState(0)
    const [average, setAverage] = useState(0)
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [userData,setUserData] = useState([])
    const [tarif,setTarif] = useState('piece_tarif')
    const [user,setUser] = useState()
    const downloadFile =() => {
        let start = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
        let end = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        alert('Запрос на получение счёта принят. В течение 5 минут на вашу почту придёт письмо с файлом')
        fetch(address+'/api/download/',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Authorization': 'Bearer '+token,
            },
            body:'{"start_date":"'+start+' 00:00:00","end_date":"'+end+' 23:59:59"}'
        })
    }
    useEffect(()=>{
        fetch(address+'/api/user/info', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            setUser(result);
          }
          else alert("Неверно введённые данные")
        })
        let date = new Date().toISOString()
        fetch(address+'/api/get/month/items',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            let final = 7.0
            let res = result[3].filter((item)=>moment(item.datetime).diff(moment(item.send_time))>=0&&item.send_time!==null&&moment(item.datetime).diff(moment(item.send_time))<20000).map((item)=>moment(item.datetime).diff(moment(item.send_time)))
            if(Object.keys(res).length!==0){let sum = res.reduce((a,b)=>(a+b));final=(sum/(Object.keys(res).length*1000)).toFixed(1)}
            let arr = Object.values(result[0])
            setKeyArray(Object.keys(result[0]))
            setValArray(Object.values(result[0]))
            setCounter(result[1])
            setUserData({...userData,data:arr});
            setLoading(false)
            Object.keys(result[3]).length!==0?setAverage(final):setAverage(0)
            if (result[2].tarif_type=='piece'){
                setTarif('piece_tarif')
                if(result[1]>500&&result[1]<750)setPrice(36)
                if(result[1]>=750)setPrice(24)
                if(result[1]<500)setPrice(48)
            }
            if(result[2].tarif_type=='fix')setTarif('fixed_tarif')
            if(result[2].tarif_type=='pack')setTarif('pack_tarif')
            if(result[2].tarif_type=='free')setTarif('free_tarif')
          }else alert(result)
        })
      },[])
    const str = (txt) => {
        return (<div className='info-txt'>
            {txt}
        </div>)
    }
    return (
        <div className='user-main'>
            <div className='personal-info'>
                <div className='block-name'>
                    <div className='block-name-text'><FormattedMessage id="user_main_person_title"/></div>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>):(
                    <div className='block-content'>
                    {str(intl.formatMessage({id:'user_main_person_login'})+": "+ user.login)}
                    {str(intl.formatMessage({id:'user_main_person_email'})+": "+ user.email)}
                    {str(intl.formatMessage({id:'user_main_person_address'})+": "+ user.address)}
                    {str(intl.formatMessage({id:'user_main_person_date_creation'})+": "+ user.datetime_creation.split('T')[0].split('-').reverse()[0]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[1]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[2])}
                    <div className='info-txt'>
                        {intl.formatMessage({id:'user_main_person_status'})+": "}{user.is_active ? (localStorage.getItem('locale')=='ru-RU'?"активен":"active"):(localStorage.getItem('locale')=='ru-RU'?"не активен":"not active")}
                    </div>
                    {str(intl.formatMessage({id:'user_main_person_organization'})+": "+ user.organization)}
                </div>
                )}
            </div>
            <div className='count-info'>
                <div className='block-name'>
                    <div className='block-name-text'><FormattedMessage id="user_bill_title"/></div>
                </div>
                {loading?(
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                ):(
                    <div className='block-content'>
                        {str(intl.formatMessage({id:'user_bill_tarif'})+": "+intl.formatMessage({id:tarif}))}
                        {str(intl.formatMessage({id:'user_bill_contract'})+": "+ user.contract)}
                        {str(intl.formatMessage({id:'user_bill_counter'})+": "+counter)}
                        {str(intl.formatMessage({id:'user_bill_price'})+": "+ (user.tarif_type=='piece'?price:'0')+" ₽")}
                        {user.tarif_type=='piece'&&str(intl.formatMessage({id:'user_bill_month_count'})+": " +price*counter+" ₽")}
                    </div>
                )}  
                <div className='count-download-button'>
                    {price!==0&&
                    <button onClick={()=>downloadFile()}>
                        <FormattedMessage id="user_bill_download_stat"/>
                    </button>}
                </div>
            </div>
            <div className='stat-info'>
                <div className='block-name'>
                    <Link to='/user/stat' className='block-name-text'><FormattedMessage id="user_main_stat_title"/></Link>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>):(
                <div className="stat-info-content">
                    <div className='stat-info-diagram'>
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: KeyArray.map((val)=>{return moment(val).format('DD.MM')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000ffffff ',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            
                                        }
                                    },
                                }
                            }}
                        />
                    </div>
                    <div className='stat-info-data'>
                        {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                        {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                        {str(intl.formatMessage({id:'user_main_stat_min'})+": "+Math.min(...ValArray))}
                        <div className='info-txt'>
                            {intl.formatMessage({id:'user_main_stat_average'})+": "+average+" "}<FormattedMessage id='sek'/>
                        </div>
                        {user.tarif_type=='piece'&&str(intl.formatMessage({id:'user_bill_month_bill'})+": " +price*counter+" ₽")}
                    </div>
                </div>)}
            </div>
        </div>
    );
};

export default Usermain;