import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GroupIcon from '@mui/icons-material/Group';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
export const AdminSidebarData = [
  {
    title: "Главная",
    path: "adminmain",
    icon:<HomeOutlinedIcon fontSize="large" />
  },
  {
    title: "Статистика",
    path: "adminstat",
    icon:<QueryStatsIcon fontSize="large" />
  },
// {
//   title: "Пользователи",
//   path: "adminusers",
//   icon:<PeopleAltOutlinedIcon fontSize="large" />
// },
// {
//   title: "Организации",
//   path: "adminorganizations",
//   icon:<BusinessIcon fontSize="large" />
// },
  {
    title: "Статистика по времени",
    path: "timestat",
    icon:<AccessTimeOutlinedIcon fontSize="large" />
  },
  {
    title: "Ошибочные исследования",
    path: "errors",
    icon:<ReportGmailerrorredIcon fontSize="large" />
  },
  {
    title: "Протокол",
    path: "protocol",
    icon:<ContentPasteIcon fontSize="large" />
  },
// {
//   title: "Новости",
//   path: "news",
//   icon:<NewspaperIcon fontSize="large" />
// },
// {
//   title: "Финансы",
//   path: "finance",
//   icon:<CurrencyRubleOutlinedIcon fontSize="large" />
// },
];