import CircularProgress from '@mui/material/CircularProgress';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Pagination } from '@mui/material';
import {useParams} from 'react-router-dom'
import { CategoryScale } from 'chart.js';
import { Bar, Line,Pie } from 'react-chartjs-2'
import CircleIcon from '@mui/icons-material/Circle';
import PrismaZoom from 'react-prismazoom'
import SearchIcon from '@mui/icons-material/Search';
import Chart from 'chart.js/auto';
import '../Admin/adminstats.css'
import moment from 'moment';
import 'moment/locale/ru';
import Cookies from 'js-cookie'
import Table from './Table'
import { address } from '../settings';


Chart.register(CategoryScale);
const Adminstat = () => {
    const [bigDiagramm,setBigDiagramm] = useState(false)
    const [podMas,setPodMas]=useState([])
    const [page,setPage]=useState(1)
    const [pages,setPages] =useState(1)
    const [elements,setElements] = useState(100)
    const [KeyArray,setKeyArray] = useState([])
    const token = Cookies.get('_auth')
    const [activeUsers, setActiveUsers] = useState([])
    const [researches,setResearches] = useState([])
    const [researchBackup,setResearchBackup] = useState([])
    const [users,setUsers] = useState([])
    const [backupUsers,setBackupUsers] = useState([])
    const [detalizationShown,setDetalizationShown] = useState(false)
    const [imageLoading,setImageLoading] = useState(false)
    const [tableLoading,setTableLoading] = useState(true)
    const [tableHidden,setTableHidden] = useState(true)
    const [selectedUsers,setSelectedUsers] = useState({})
    const [normCount,setNormCount] = useState(0)
    const [counter,setCounter] = useState(0)
    const [detailCount,setDetailCount] = useState(0)
    const [url,setUrl]=useState('')
    const [detalizationLoading,setDetalizationLoading] = useState(false)
    const [pathologies,setPathologies] = useState({})
    const [mas,setMas] = useState([])
    const id = Object.values(useParams())
    const colors = ['#C71585','#00FFFF','#00FF00','#FF4500','#DC143C','#FF00FF','#4B0082','#0000CD','#2F4F4F','#708090','#7B68EE','#4169E1','#00BFFF','#5F9EA0','#40E0D0','#30BA8F','#FB607F','#1DACD6','#FADBC8','#753313','#3D642D','#CC7722','#EE204D']
    const [loading,setLoading] = useState(true)
    const [graphLoading,setGraphLoading] = useState(true)
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [bigTable,setBigTable] = useState(false)
    const [sort,setSort] = useState({
        name:'datetime',
        direction:false  //True = на возрастание; False = на убывание 
    })
    document.addEventListener('keydown',function(e){
        if(e.key=='Escape'||e.key=='Esc'||e.key==27){setIsImgEditorShown(false);setDetalizationShown(false)}
    })
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        let date = new Date().toISOString()
        let obj={}
        fetch(address+'/users/for/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
            if(result!==false){
            setUsers(result)
            setBackupUsers(result)
            result.map((item)=>obj[`${item.id}`]=false)
            setSelectedUsers(obj)
            setLoading(false)
            setGraphLoading(false)
          }
            else alert("Неверно введённые данные")
        })
        id!=null&&id!=''&&
        fetch(address+'/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            },
          body:'{"id_mas":{"mas":['+id+']},"mode":"comparative","date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:59"}'
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            let massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={label: {display: false},data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setMas(massive)
            setCounter(resp[1])
            setLoading(false)
            setGraphLoading(false)
        })
            setTimeout(() => {
                if(id!=null&&id!='')document.getElementById(id).checked=true
            }, 1500);
            checkActiveUsers()
            setInterval(()=>{
                checkActiveUsers()
            },120000)
    },[])
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        sendData()
    }
    const sortTable = (field) =>{
        let masiv = []
        if(sort.name===field){
            if (sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
                if(field=='sopinstanceuid')masiv = [...researches].sort((a,b)=>a['sopinstanceuid'].localeCompare(b['sopinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
                setSort({...sort,['name']:field,['direction']:false})
                setResearches(masiv)
            }
            if(!sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(a['datetime'])-moment(b['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>a['prob']-b['prob'])
                if(field=='sopinstanceuid')masiv = [...researches].sort((a,b)=>b['sopinstanceuid'].localeCompare(a['sopinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>b['organization'].localeCompare(a['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>b['classes'].localeCompare(a['classes']))
                setResearches(masiv)
                setSort({...sort,['name']:field,['direction']:true})
            }
        }
        else{
            if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
            if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
            if(field=='sopinstanceuid')masiv = [...researches].sort((a,b)=>a['sopinstanceuid'].localeCompare(b['sopinstanceuid']))
            if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
            if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
            setSort({...sort,['name']:field,['direction']:false})
            setResearches(masiv)
        }
    }
    const handleSelect = () => {//Обработчик изменений полей для ввода даты
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('ad-select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('ad-select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('ad-select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('ad-select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('ad-select').value='previous_quarter'
        else document.getElementById('ad-select').value='random'
    }
    const sel = () =>{
        var val = document.getElementById('selectall').checked
        let obj ={}
        Object.keys(selectedUsers).map(item=>parseInt(item)).map(item=>obj[item]=val)
        setSelectedUsers(obj)
    }
    const sendData = () => {//отправка данных о периоде и получение статистики по выбранным пользователям
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        setDetalizationShown(false)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setGraphLoading(true)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":elements*page
        }
        fetch(address+'/get/users/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            var massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension: 0,hoverRadius: 5,hoverBackgroundColor: 'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setPage(1)
            setMas(massive)
            setCounter(resp[1])
            setGraphLoading(false)
            setLoading(false)
        })
        setTableHidden(false)
        setTableLoading(true)
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(1)
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/elements); i++){
                            podmas.push(result.slice((i*elements), (i*elements) + elements));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                    })
    }
    const closeImgEditor  = () => {//Закрытие/открытие изображения
        setIsImgEditorShown(false);
    };   
    const getImage = (id) => {
        setIsImgEditorShown(true)
        setImageLoading(true)
        document.getElementById(id).style.backgroundColor='lightskyblue'
        fetch(address+'/api/get/research/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else {
                    setImageLoading(false);
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    const detalization = () =>{
        var id_massive = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        if(id_massive.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        setTableHidden(true)
        setDetalizationShown(true)
        setDetalizationLoading(true)
        let id_mas = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        fetch(address+'/api/get/researches', {///api
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            },
          body:'{"id_mas":{"mas":['+id_mas+']},"mode":"comparative","date_start":"'+start+' 00:00:00","date_end":"'+end+' 23:59:59"}'})
            .then(resp=>{if(resp.ok)return resp.json()})
                .then(result=>{
                    let reg = /[А-Яа-яA-Za-z\.,/\s]/gi
                    setDetailCount(result.length)
                    var classes = result.map((item)=>item.classes)
                    let str = JSON.stringify(classes).match(reg).join('').split(',').map((item)=>item.trim())
                    let object={}
                    str.forEach(function (x) { object[x] = (object[x] || 0) + 1; });
                    setNormCount(object[""])
                    delete object[""]
                    const ordered = Object.keys(object).sort().reduce(
                        (obj, key) => {
                          obj[key] = object[key];
                          return obj;
                        },
                        {}
                    );
                    setDetalizationLoading(false)
                    setPathologies(ordered)
                })
    }
    const handler = () =>{
        let masiv = []
        masiv = [...researchBackup].filter((item)=>item.sopinstanceuid.indexOf(document.getElementById('research').value)!=-1)
        setResearches(masiv)
    }
    const checkActiveUsers = () =>{
        fetch(address+'/api/get/active/users', {///api
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            }}).then((result)=>result.json()).then(res=>{
                setActiveUsers(res)
            })
    }
    const handlePage = (event, value) => {
        setPage(value);
        setResearches(podMas[value-1])
    }
    const handleElements =() => {
        setTableLoading(true)
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        let number = parseInt(document.getElementById("elements").value)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":number*page
        }
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/number); i++){
                            podmas.push(result.slice((i*number), (i*number) + number));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                        setPage(1)
                    })
    }
    const nextPage =() => {
        setTableLoading(true)
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))        
        let number = parseInt(document.getElementById("elements").value)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":(number*(page+1))
        }
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <result.length/number; i++){
                            podmas[i]=result.slice((i*number), (i*number) + number);
                        }
                        setPage(podmas.length)
                        setPodMas(podmas)
                        setResearches(podmas[podmas.length-1])
                        setTableLoading(false)
                    })
    }
    const hideTable = () =>{
        setTableHidden(true)
        setBigTable(false)
    }
    const organizationHandler = (e) =>{
        let masiv = []
        masiv = [...backupUsers].filter((item)=>item.organization.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1||item.full_name.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1)
        setUsers(masiv)
    }
    const clearOrganization = () =>{
        document.getElementById('organization').value=''
        setUsers(backupUsers)
    }
    return (
        <div className='admin-stat'>
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow'>
                    <div className='admin-edit-header'>
                        <div className='admin-edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                        <div className='admin-edit-image' id='editimage'>
                            <PrismaZoom style={{display:'flex',justifyContect:'center',alignItem:'center',width:'auto',height:'auto',maxHeight:'100%',maxWidth:'100%'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom>
                        </div>)}
                </div>
            )}
            {detalizationShown&&
                <div className='detalization-active'>
                    <div className='detalization-header'>
                        <div className='detalization-header-info'>
                            Данные за период с {moment(document.getElementById('start').value).format("D MMMM YYYY")} по {moment(document.getElementById('end').value).format("D MMMM YYYY")}
                        </div>
                        <div className='datalization-header-active'>
                            <button onClick={()=>setDetalizationShown(false)}>Закрыть</button>
                        </div>
                    </div>
                    {detalizationLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='detalization-body'>
                            <div className='detalization-body-text'>
                                <div className='detalization-text'>Общее количество исследований: {detailCount}</div>
                                <div className='detalization-text'>Норм: {((normCount/detailCount).toFixed(3)*100).toFixed(1)}% Патологий: {((1-(normCount/detailCount).toFixed(3)).toFixed(3)*100).toFixed(1)}%</div>
                            </div>
                            <div className='detalization-body-diagram'>
                                <Pie style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                    labels:["Норма","С патологиями"],
                                    datasets:[
                                        {
                                            data:[normCount,(detailCount-normCount)],
                                            backgroundColor: [
                                                "#63FF84",
                                                "#FF6384",
                                                ],
                                            borderWidth: 1,
                                            borderColor:'grey'
                                        },
                                    ]
                                }}
                                options={{
                                }}/>
                            </div>
                            <div className='pathologies-text'>
                                <div className='pathologies-text-title'>
                                    Распределение патологий
                                </div>
                            </div>
                            <div className='pathologies-diagram'>
                                <Bar style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                        labels:Object.keys(pathologies),
                                        label:'yfpdfybt',
                                        datasets:[
                                            {
                                                label:'',
                                                data:Object.values(pathologies),
                                                backgroundColor: [
                                                    '#7EEB46',
                                                    '#F2BD3A',
                                                    '#DB4A40',
                                                    '#7D3AF2',
                                                    '#38D8E8',
                                                    '#DB4ACC',
                                                    '#4C6FF5',
                                                    '#6AB7F5',
                                                    '#C3EB9B',
                                                    '#EB557F'
                                                ],
                                                borderWidth: 1,
                                                borderColor:'grey'
                                            },
                                        ]
                                    }} options={{
                                        interaction: {
                                            intersect: false,
                                            index:true
                                        },
                                        plugins: {
                                            legend: {
                                                title:"Распределение по патологяим",
                                                display: false,
                                                labels: {
                                                    display: false,
                                                    title:"Распределение по патологяим",
                                                }
                                            },
                                        },
                                        tooltips: {
                                            enabled: true
                                        },
                                        hover: {
                                            animationDuration: 1
                                        },
                                        animation: {
                                        duration: 1,
                                        }

                                        }}/>
                            </div>
                        </div>
                    )}
                </div>
            }
            <div className='admin-stat-table'>
                <div className='admin-stat-table-title'>
                    <div className='admin-stat-table-title-text'>Пользователи</div>
                </div>
                <div className='admin-stat-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead' >
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th'><input type='checkbox'  onChange={()=>sel()} id='selectall'/></th>
                                        <th className='stat-tr-th' id='stat-tr-th'><input id='organization' onBlur={()=>document.getElementById('organization').placeholder='Организация'} onFocus={()=>document.getElementById('organization').placeholder=''} type='text' placeholder='Организация' onChange={organizationHandler}/><CloseIcon className='close-icon' title='Очистить поле' sx={{color:'white',fontSize:'14'}} onClick={()=>clearOrganization()}/></th>
                                        <th className='stat-tr-th'>Логин</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )}
                                    {users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr' onClick={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})}>
                                            <td className='stat-tr-td'><input id = {item.id} checked={selectedUsers[`${item.id}`]} onChange={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})} type='checkbox' name='input'/></td>
                                            <td className='stat-tr-td'>
                                                <div className="stat-tr-td-div">
                                                    <div className='stat-status' title='Пользователь онлайн'>
                                                        {activeUsers.indexOf(item.id)===-1?(
                                                            <div></div>):(
                                                            <CircleIcon sx={{color:'#00FEFF'}} fontSize="10"/>
                                                        )}
                                                    </div>
                                                    <div className='stat-tr-td-info'>
                                                        {item.organization}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='stat-tr-td'>{item.login}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className='ad-stat-select'>
                    <select id='ad-select' onChange={()=>handleChange()}>
                        <option value='default'>Выбрать период</option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='last_week'>Прошлая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-stat-table-date'>
                    <input className="admin-stat-table-date-input" id = 'start' type="date" onChange={()=>handleSelect()}/>
                    <input className="admin-stat-table-date-input" id = 'end' type="date" onChange={()=>handleSelect()}/>
                </div>
                <div className='admin-stat-table-date-check'>
                    Общая
                    <label className="switch">
                        <input type="checkbox" id="check" onChange={()=>sendData()}/>
                        <span className="slider round"></span>
                    </label>
                    Сравнительная
                </div>
                <div className='admin-stat-table-date-send'>
                    <button onClick={()=>sendData()}>Отобразить данные</button>
                </div>
            </div>
            <div className={bigDiagramm?"admin-stat-diagram-big":"admin-stat-diagram"}>
                <div className='admin-stat-diagram-title'>
                    <div className='admin-stat-diagram-title-container-1'>
                        <div className='admin-stat-diagram-title-text'>
                            Статистика
                        </div>
                    </div>
                    <div className='admin-stat-diagram-title-container-2'>
                        {graphLoading?(
                            <div className='div-loading'>
                            </div>
                        ):(
                            <div className='admin-stat-diagram-title-info'>
                                Всего исследований: {counter}
                            </div>
                        )}
                    </div>
                    <div className='big-diagramm'>
                        {bigDiagramm?
                        (
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(false)}>
                                <CloseFullscreenIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        ):(
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(true)}>
                                <OpenInFullIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        )}
                    </div>
                </div>
                <div className='admin-stat-diagram-data'>
                    {graphLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):
                    (<Line style={{background:'white',borderRadius:10,padding:10}}
                        data={{
                        labels:moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                        datasets:mas
                        }}
                        options= {{
                            pointRadius: 1,
                            pointHoverRadius: 7,
                            responsive: true,
                            interaction: {
                              intersect: false,
                            },
                            scales: {
                              y: {
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                },
                                title:{
                                    display:!(document.documentElement.scrollWidth<500),
                                    text:'Исследования'
                                },
                                display: true,
                                beginAtZero: true,
                                precision: 0,
                              },
                              x:{
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                    },
                                title:{
                                display:!(document.documentElement.scrollWidth<500),
                                text:'Дата'},
                            },
                            },
                          }}/>)}
                </div>
                <div className='admin-stat-diagram-btn'>
                    <button id='reserach-table-btn' onClick={()=>detalization()}>Детальная статистика</button>
                </div>
            </div>
            <div className={bigTable?'admin-research-table-active':'admin-research-table'} hidden={tableHidden} id='researchTable'>
                <div className={bigTable?'admin-research-table-close-active':'admin-research-table-close'}>
                    <div className='pagination-block'>
                        <div className='select-count'>
                            Отображать:
                            <select id="elements" onChange={handleElements}>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={400}>400</option>
                                <option value={800}>800</option>
                            </select>
                        </div>
                        <div className='serach-field'>
                            <SearchIcon/>
                            <input type='text' id = 'research' placeholder='Введите SOP' onChange={handler}/>
                        </div>
                        <Pagination count={pages} siblingCount={0} page={page} onChange={handlePage} hideNextButton={page==pages} defaultPage={1}/>
                            {page==pages&&
                                <div className='pag-div' onClick={()=>nextPage()}>
                                    Ещё
                                </div>
                            }
                    </div>
                    {bigTable?
                        (<button id='researchTableBtn' onClick={()=>setBigTable(false)}>Свернуть табицу</button>):
                        (<button id='researchTableBtn' onClick={()=>setBigTable(true)}>Развернуть таблицу</button>)
                    }
                    <button onClick={()=>hideTable()}>Закрыть</button>
                </div>
                <div className={bigTable?'admin-research-table-data-active':'admin-research-table-data'}>
                    {tableLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(<Table researches={researches} sortTable={sortTable} getImage={getImage} handler={handler} sort={sort} setSort={setSort}/>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Adminstat;
