import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect,useState } from 'react';
import {FormattedMessage,useIntl} from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { CategoryScale } from 'chart.js';
import PrismaZoom from 'react-prismazoom'
import { Bar,Pie } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/ru';
import './stats.css'
import { address } from '../settings';

Chart.register(CategoryScale);

const Stats = () => {
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [imageLoading,setImageLoading] = useState(false)
    const [loading,setLoading] = useState(true)
    const [average, setAverage] = useState(0)
    const [counter,setCounter] = useState(0)
    const [url,setUrl]=useState('')
    const [detailCount,setDetailCount] = useState(0)
    const [detalizationShown,setDetalizationShown] = useState(false)
    const [activeDetalization,setActiveDetalization] = useState(false)
    const [items,setItems]=useState([{}])
    const [downloadState,setDownloadState] = useState(false)
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [price,setPrice] = useState(0)
    const token = Cookies.get('_auth')
    const intl = useIntl()
    const [normCount,setNormCount] = useState(0)
    const [pathologies,setPathologies] = useState({})
    const [detalizationLoading,setDetalizationLoading] = useState(false)
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let date = new Date().toISOString()
        fetch(address+'/api/get/month/items',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:'{"date":"'+date+'"}'
          }).then(resp=>{
              if(resp.ok) return resp.json()
              else return false
          }).then(result=>{
            if(result!==false){
              let final = 7.0
              let res = result[3].filter((item)=>moment(item.datetime).diff(moment(item.send_time))>=0&&item.send_time!==null&&moment(item.datetime).diff(moment(item.send_time))<20000).map((item)=>moment(item.datetime).diff(moment(item.send_time)))
              if(Object.keys(res).length!==0){let sum = res.reduce((a,b)=>(a+b));final=(sum/(Object.keys(res).length*1000)).toFixed(1)}
              setKeyArray(Object.keys(result[0]))
              setValArray(Object.values(result[0]))
              setItems(result[3])
              setCounter(result[1])
              setLoading(false)
              Object.keys(result[3]).length!==0?setAverage(final):setAverage(0)
              if (result[2].tarif_type=='piece'){
                  if(result[1]>500&&result[1]<750)setPrice(36)
                  if(result[1]>=750)setPrice(24)
                  if(result[1]<500)setPrice(48)
              }
            }else alert(result)
          })
    },[])
    const downloadFile =() => {//метод для скачивания файла с сервера
        setDetalizationShown(true)
        let reg = /[А-Яа-я\.,/\s]/gi
        setDetailCount(items.length)
        var classes = items.map((item)=>item.classes)
        let str = JSON.stringify(classes).match(reg).join('').split(',').map((item)=>item.trim())
        let object={}
        str.forEach(function (x) { object[x] = (object[x] || 0) + 1; });
        setNormCount(object[""])
        delete object[""]
        const ordered = Object.keys(object).sort().reduce(
            (obj, key) => { 
                obj[key] = object[key]; 
                return obj;
            }, 
            {}
        );
        setPathologies(ordered)
    }
    const setPeriod = () => {// Отправка даты начала и конца периода для получения исследований
        if(!moment(document.getElementById('start').value).isValid()){alert('Указана несуществующая дата начала периода');return 1;}
        if(!moment(document.getElementById('end').value).isValid()){alert('Указана несуществующая дата конца периода');return 1;}
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        if(document.getElementById('start').value>=document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        setLoading(true)
        let start = document.getElementById('start').value;
        let end = document.getElementById('end').value;
        fetch(address+'/api/get/period/data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
          },
          body:'{"start_date":"'+start+' 00:00:00","end_date":"'+end+' 23:59:59"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            let final = 7
            let res = result[2].filter((item)=>item.send_time!==null&&moment(item.datetime).diff(moment(item.send_time))<20000&&moment(item.datetime).diff(moment(item.send_time))>0).map((item)=>moment(item.datetime).diff(moment(item.send_time)))
            if(Object.keys(res).length!==0){let sum = res.reduce((a,b)=>(a+b));final=(sum/(Object.keys(res).length*1000)).toFixed(1)}
            setKeyArray(Object.keys(result[0]))
            setValArray(Object.values(result[0]))
            setCounter(result[1])
            setItems(result[2])
            Object.keys(result[2]).length!==0?setAverage(final):setAverage(0)
            setLoading(false)}
            else alert("Неверно указан период")
        })
    }
    const str = (txt) => { //Отображение текста строк
        return (
            <div className='info-txt'>
                {txt}
            </div>)
    }
    const handleSelect = () => {//Обработчик изменения select компонента
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('select').value='previous_quarter'
        else document.getElementById('select').value='random'
    }
    const closeImgEditor  = () => {setIsImgEditorShown(false);};//Закрытие/открытие изображения
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        setPeriod()
    }
    const getImage = (id) => {
        setIsImgEditorShown(true)
        setImageLoading(true)
        document.getElementById(id).style.backgroundColor='lightskyblue'
        fetch(address+'/api/get/research/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else { 
                    setImageLoading(false);
                    console.log('что-то пошло не так')
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    const closeTableDetalization = () =>{
        setActiveDetalization(false)
        setDetalizationShown(false)
    }
    return (
        <div className='stat-main'>
            {detalizationShown&&
                <div className={activeDetalization?'detalization-active':'detalization'}>
                    <div className='detalization-header'>
                        <div className='detalization-header-info'>
                            <FormattedMessage id="detalization_from_to"/> {moment(document.getElementById('start').value).format("DD.MM.YYYY")} - {moment(document.getElementById('end').value).format("DD.MM.YYYY")}
                        </div>
                        <div className='datalization-header-active'>
                            {activeDetalization?
                                (<button id='researchTableBtn' onClick={()=>setActiveDetalization(false)}><FormattedMessage id="detalization_collapse"/></button>):
                                (<button id='researchTableBtn' onClick={()=>setActiveDetalization(true)}><FormattedMessage id="detalization_expand"/></button>)
                            }
                            <button onClick={()=>closeTableDetalization()}><FormattedMessage id="detalization_close"/></button>
                        </div>
                    </div>
                    {detalizationLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='detalization-body'>
                            <div className='detalization-body-text'>
                                <div className='detalization-text'><FormattedMessage id="detalization_count"/> {detailCount}</div>
                                <div className='detalization-text'><FormattedMessage id="detalization_norm"/>: {((normCount/detailCount).toFixed(3)*100).toFixed(1)}% <FormattedMessage id="detalization_pathologies"/>: {((1-(normCount/detailCount).toFixed(3)).toFixed(3)*100).toFixed(1)}%</div>
                            </div>
                            <div className='detalization-body-diagram'>
                                <Pie style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                    labels:[intl.formatMessage({id:'detalization_norm'}),intl.formatMessage({id:'detalization_pathologies'})],
                                    datasets:[
                                        {
                                            data:[normCount,(detailCount-normCount)],
                                            backgroundColor: [
                                                "#63FF84",
                                                "#FF6384",
                                                ],
                                            borderWidth: 1,
                                            borderColor:'grey'
                                        },
                                    ]
                                }}
                                options={{
                                }}/>
                            </div>
                            <div className='pathologies-text'>
                                <div className='pathologies-text-title'>
                                    <FormattedMessage id="detalization_distribution"/>
                                </div>                            
                            </div>
                            <div className='pathologies-diagram'>
                                <Bar style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                        labels:Object.keys(pathologies),
                                        label:'yfpdfybt',
                                        datasets:[
                                            {
                                                label:'',
                                                data:Object.values(pathologies),
                                                backgroundColor: [
                                                    '#7EEB46',
                                                    '#F2BD3A',
                                                    '#DB4A40',
                                                    '#7D3AF2',
                                                    '#38D8E8',
                                                    '#DB4ACC',
                                                    '#4C6FF5',
                                                    '#6AB7F5',
                                                    '#C3EB9B',
                                                    '#EB557F'
                                                ],
                                                borderWidth: 1,
                                                borderColor:'grey'
                                            },
                                        ]
                                    }} options={{
                                        interaction: {
                                            intersect: false,
                                            index:true
                                        },
                                        plugins: {
                                            legend: {
                                                title:"Распределение по патологяим",
                                                display: false,
                                                labels: {
                                                    display: false,
                                                    title:"Распределение по патологяим",
                                                }
                                            },
                                        }
                                        }}/>
                            </div>
                        </div>
                    )}
                </div>
            }
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow'>
                    <div className='admin-edit-header'>
                        <div className='admin-edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                        <div className='admin-edit-image' id='editimage'>
                            <PrismaZoom animDuration='0' style={{display:'flex',alignItem:'center',width:'auto',height:'auto',maxHeight:'100%',maxWidth:'100%'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom>
                        </div>)}
                </div>
            )}
            <div className='stat-main-info'>
                <div className='stat-input'>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='start' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='end' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-button'>
                        <select id='select' onChange={()=>handleChange()}>
                            <option value='default'><FormattedMessage id="user_stat_select_0"/></option>
                            <option value='current_week'><FormattedMessage id="user_stat_select_1"/></option>
                            <option value='last_week'><FormattedMessage id="user_stat_select_11"/></option>
                            <option value='current_month'><FormattedMessage id="user_stat_select_2"/></option>
                            <option value='previous_month'><FormattedMessage id="user_stat_select_3"/></option>
                            <option value='current_quarter'><FormattedMessage id="user_stat_select_4"/></option>
                            <option value='previous_quarter'><FormattedMessage id="user_stat_select_5"/></option>
                            <option value='current_year'><FormattedMessage id="user_stat_select_6"/></option>
                            <option value='previous_year'><FormattedMessage id="user_stat_select_7"/></option>
                            <option value='random'><FormattedMessage id="user_stat_select_8"/></option>
                        </select>
                    </div>
                    <div className='stat-input-button'><button  onClick={()=>{setPeriod()}}><FormattedMessage id="display"/></button></div>
                </div>
                <div className='stat-input-diagram'>
                    <div className='stat-diagram'>
                    {loading?
                        (<div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>):(
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000fff',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            display: false
                                        }
                                    },
                                }
                            }}
                        />)}
                    </div>
                </div>
                {loading?(<div className='loading-div-block'>
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                </div>):
                (<div className='stat-info-text'>
                    {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_min'})+" "+Math.min(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                    <div className='info-txt'>
                        {intl.formatMessage({id:'user_main_stat_average'})+": "+average+" "}<FormattedMessage id='sek'/>
                    </div>
                    {str(intl.formatMessage({id:'user_bill_month_bill'})+": "+price*counter+" ₽")}
                </div>)}
                <div className='stat-info-button'>
                    {!downloadState?(<button onClick={()=>downloadFile()}><FormattedMessage id="download_stat_for_period"/></button>):(<CircularProgress sx={{color:'white'}}/>)}
                </div>
            </div>
            <div className='research-table'>
                {loading?(
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                    ):(
                    <div className='research-table-data'>
                    <table className='res-table'>
                        <thead id='res-thead'>
                            <tr id='tr-th'>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_id"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_date"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_proccessing_time"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_probability"/></th>
                                <th className='rs-th'><FormattedMessage id="user_stat_table_classes"/></th>
                            </tr>
                        </thead>
                        <tbody className='res-tbody'>
                            {items.length==0&&(
                                <tr>
                                    <td className='rs-td' colSpan='5'><FormattedMessage id="no_studies"/></td>
                                </tr>)}
                            {items.map((item)=>
                                <tr key={item.id} className='tr-td' id={item.id} onClick={()=>getImage(item.id)}>
                                    <td className='rs-td'>{item.sopinstanceuid}</td>
                                    <td className='rs-td'>{moment(item.datetime).format('DD.MM.YYYY HH:mm:ss')}</td>
                                    <td className='rs-td'>{(item.send_time!==null && moment(item.requestcstoretime).diff(moment(item.send_time))<20000 && moment(item.requestcstoretime).diff(moment(item.send_time))>0)?((moment(item.requestcstoretime).diff(moment(item.send_time))/1000).toFixed(1)):(7)}</td>
                                    <td className='rs-td'>{item.prob}</td>
                                    <td className='rs-td'>{item.classes}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>      
                </div>)}
            </div>
        </div>
    );
};

export default Stats;