import React, { useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import { enSidebarData, SidebarData } from "./sidebardata";
import ftz from './img/ftz1.svg'
import ftz_en from './img/ftz_en.svg'
import './sidebar.css'
import { AdminSidebarData } from "./adminsidebar";
import CircularProgress from '@mui/material/CircularProgress';
import {useAuthUser} from 'react-auth-kit';

const Sidebar = () => {
    const change = () => {document.getElementById('sidebar').style.left="-300px"}
    const role = useAuthUser()
    const [loading,setLoading] = useState(true)
    const param = useParams()
    const [paths,setPaths] = useState([])
    const[page,setPage] = useState('')
    useEffect(()=>{
      let mas=[]
      setPaths(mas)
      if(role().role){
        role().role=='user'?
        SidebarData.map(item=>mas.push(item.path))
        :
        AdminSidebarData.map(item=>mas.push(item.path))
      }
      setPage(mas[0])
      setLoading(false)
      setPage(Object.values(param)[0])
    },[])
    useEffect(()=>{
      paths.map(item=>{
        if(item===page){
          if(document.getElementById(item))document.getElementById(item).style.backgroundColor='#1f93d1'
        }
        else{
          if(document.getElementById(item))document.getElementById(item).style.backgroundColor='transparent'
        }
      })
    },[param])
  const changeColor = (path) => {
    setPage(path)
  }
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-items">
        <div className="sidebar-logo">
          {role().role!=="admin"?localStorage.getItem('locale')=='ru-RU'?(<img src={ftz} alt='logo'/>):(<img src={ftz_en} alt='logo'/>):(<img src={ftz} alt='logo'/>)}
        </div>
        {!loading?
        (<div className="sidebar-data">
          {role().role==="admin" ? 
          (<ul className="sidebar-list">
          {AdminSidebarData.map((val, key) => {
            return (
              <li key={key}>
                <div className="sidebar-list-row" onClick={()=>changeColor(val.path)}>
                  <Link to={`${val.path}`} onClick={()=>{if(document.documentElement.scrollWidth<1100)change()}} id={val.path}>
                    <div className="sidebar-list-icon">
                      {val.icon}
                    </div>
                    <div className="sidebat-list-title">
                      {val.title}
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}
          </ul>):
          (<ul className="sidebar-list">
            {localStorage.getItem('locale')=='ru-RU'?
            SidebarData.map((val, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-list-row" onClick={()=>changeColor(val.path)} >
                  <Link to={`${val.path}`} onClick={()=>{if(document.documentElement.scrollWidth<1100)change()}} id={val.path}>
                    <div className="sidebar-list-icon">
                        {val.icon}
                      </div>
                      <div className="sidebat-list-title">
                        {val.title}
                      </div>
                    </Link>
                  </div>
                </li>
              );
            }):enSidebarData.map((val, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-list-row">
                  <Link to={`${val.path}`} onClick={()=>{if(document.documentElement.scrollWidth<1100)change()}}>
                    <div className="sidebar-list-icon">
                        {val.icon}
                      </div>
                      <div className="sidebat-list-title">
                        {val.title}
                      </div>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>)}
        </div>):
        (<div className="loading-div">
          <CircularProgress sx={{color:'white'}}/>
        </div>)
        }
      </div>
    </div>
  );
};

export default Sidebar;
