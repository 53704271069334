import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect,useState } from 'react';
import ErrorsTable from '../Admin/ErrorsTable';
import { CategoryScale } from 'chart.js';
import PrismaZoom from 'react-prismazoom'
import { Bar,Pie } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/ru';
import './stats.css'
import UserErrorsTable from './UserErrorsTable'
import { address } from '../settings';

Chart.register(CategoryScale);

function UserErrors(props) {
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [imageLoading,setImageLoading] = useState(false)
    const [loading,setLoading] = useState(true)
    const [counter,setCounter] = useState(0)
    const [url,setUrl]=useState('')
    const [items,setItems]=useState([])
    const [backupItems,setBackupItems] = useState([])
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const token = Cookies.get('_auth')
    const intl = useIntl()
    const [sort,setSort] = useState({
        name:'datetime',
        direction:false  //True = на возрастание; False = на убывание 
    })
    const sortTable = (field) =>{
        let masiv = []
        if(sort.name===field){
            if (sort.direction){
                if(field=='datetime')masiv = [...items].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
                if(field=='sopinstanceuid')masiv = [...items].sort((a,b)=>a['sopinstanceuid'].localeCompare(b['sopinstanceuid']))
                if(field=='status')masiv = [...items].sort((a,b)=>b['status']-a['status'])
                if(field=='image_crop_class')masiv = [...items].sort((a,b)=>b['image_crop_class']-a['image_crop_class'])
                if(field=='error_message')masiv = [...items].sort((a,b)=>a['error_message'].localeCompare(b['error_message']))
                setSort({...sort,['name']:field,['direction']:false})
                setItems(masiv)
            }
            if(!sort.direction){
                if(field=='datetime')masiv = [...items].sort((a,b)=>moment(a['datetime'])-moment(b['datetime']))
                if(field=='sopinstanceuid')masiv = [...items].sort((a,b)=>b['sopinstanceuid'].localeCompare(a['sopinstanceuid']))
                if(field=='status')masiv = [...items].sort((a,b)=>a['status']-b['status'])
                if(field=='image_crop_class')masiv = [...items].sort((a,b)=>a['image_crop_class']-b['image_crop_class'])
                if(field=='error_message')masiv = [...items].sort((a,b)=>b['error_message'].localeCompare(a['error_message']))
                setItems(masiv)
                setSort({...sort,['name']:field,['direction']:true})
            }
        }
        else{
            if(field=='datetime')masiv = [...items].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
            if(field=='sopinstanceuid')masiv = [...items].sort((a,b)=>a['sopinstanceuid'].localeCompare(b['sopinstanceuid']))
            if(field=='status')masiv = [...items].sort((a,b)=>b['status']-a['status'])
            if(field=='image_crop_class')masiv = [...items].sort((a,b)=>b['image_crop_class']-a['image_crop_class'])
            if(field=='error_message')masiv = [...items].sort((a,b)=>a['error_message'].localeCompare(b['error_message']))
            setSort({...sort,['name']:field,['direction']:false})
            setItems(masiv)
        }
    }
    const handleResearches = () =>{
        let masiv = []
        masiv = [...backupItems].filter((item)=>item.sopinstanceuid.indexOf(document.getElementById('research1').value)!=-1)
        setItems(masiv)
    }
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        setLoading(true)
        let start = document.getElementById('start').value;
        let end = document.getElementById('end').value;
        let body={
            "id_mas":{"mas":[0]},
            "mode":"general",
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":0
        }
        fetch(address+'/get/users/errors/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            setValArray(Object.values(Object.values(resp[0])[0]))
            setCounter(resp[1])
        })
        fetch(address+'/get/users/errors', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setItems(result)
                        setBackupItems(result)
                        setLoading(false)
                    })
    },[])
    const setPeriod = () => {// Отправка даты начала и конца периода для получения исследований
        if(!moment(document.getElementById('start').value).isValid()){alert('Указана несуществующая дата начала периода');return 1;}
        if(!moment(document.getElementById('end').value).isValid()){alert('Указана несуществующая дата конца периода');return 1;}
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        if(document.getElementById('start').value>=document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        setLoading(true)
        let start = document.getElementById('start').value;
        let end = document.getElementById('end').value;
        let body={
            "id_mas":{"mas":[0]},
            "mode":"general",
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":0
        }
        fetch(address+'/get/users/errors/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            setValArray(Object.values(Object.values(resp[0])[0]))
            setCounter(resp[1])
        })
        fetch(address+'/get/users/errors', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setItems(result)
                        setBackupItems(result)
                        setLoading(false)
                    })
    }
    const str = (txt) => { //Отображение текста строк
        return (
            <div className='info-txt'>
                {txt}
            </div>)
    }
    const handleSelect = () => {//Обработчик изменения select компонента
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('select').value='previous_quarter'
        else document.getElementById('select').value='random'
    }
    const closeImgEditor  = () => {setIsImgEditorShown(false);};//Закрытие/открытие изображения
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        setPeriod()
    }
    const getImage = (id) => {
        setIsImgEditorShown(true)
        setImageLoading(true)
        document.getElementById(id).style.backgroundColor='lightskyblue'
        fetch(address+'/api/get/error/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else { 
                    setImageLoading(false);
                    console.log('что-то пошло не так')
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    return (
        <div className='stat-main'>
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow'>
                    <div className='admin-edit-header'>
                        <div className='admin-edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                            <>
                                <PrismaZoom className='prizm' animDuration='0' style={{width:'auto',height:'auto',maxWidth:'100%',maxHeight:'93%',boxSizing:'border-box',display:'flex',justifyContent:'center',alignItem:'center'}}>
                                    <img id='image' src={url}/>
                                </PrismaZoom> 
                            </>)
                    }
                </div>
            )}
            <div className='stat-main-info'>
                <div className='stat-input'>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='start' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='end' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-button'>
                        <select id='select' onChange={()=>handleChange()}>
                            <option value='default'><FormattedMessage id="user_stat_select_0"/></option>
                            <option value='current_week'><FormattedMessage id="user_stat_select_1"/></option>
                            <option value='last_week'><FormattedMessage id="user_stat_select_11"/></option>
                            <option value='current_month'><FormattedMessage id="user_stat_select_2"/></option>
                            <option value='previous_month'><FormattedMessage id="user_stat_select_3"/></option>
                            <option value='current_quarter'><FormattedMessage id="user_stat_select_4"/></option>
                            <option value='previous_quarter'><FormattedMessage id="user_stat_select_5"/></option>
                            <option value='current_year'><FormattedMessage id="user_stat_select_6"/></option>
                            <option value='previous_year'><FormattedMessage id="user_stat_select_7"/></option>
                            <option value='random'><FormattedMessage id="user_stat_select_8"/></option>
                        </select>
                    </div>
                    <div className='stat-input-button'><button  onClick={()=>{setPeriod()}}><FormattedMessage id="display"/></button></div>
                </div>
                <div className='stat-input-diagram'>
                    <div className='stat-diagram'>
                    {loading?
                        (<div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>):(
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000fff',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            display: false
                                        }
                                    },
                                }
                            }}
                        />)}
                    </div>
                </div>
                {loading?(<div className='loading-div-block'>
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                </div>):
                (<div className='stat-info-text'>
                    {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_min'})+" "+Math.min(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                </div>)}
            </div>
            <div className='research-table'>
                <div className='admin-research-table-close'>
                    <div className='pagination-block'>
                        <div className='serach-field'>
                            <SearchIcon/>
                            <input type='text' id = 'research1' placeholder='Введите SOP' onChange={handleResearches}/>
                        </div>
                    </div>
                </div>
                <div className='admin-research-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(<UserErrorsTable researches={items} sortTable={sortTable} getImage={getImage} sort={sort} setSort={setSort}/>)
                    }
                </div>
            </div>
        </div>
    );
}

export default UserErrors;