import CircularProgress from '@mui/material/CircularProgress';
import React, { useState,useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import "./finance.css"

const address = 'http://192.168.161:8080'

const Finance = (props) => {
    const [backupUsers,setBackupUsers] = useState([])
    const [loading,setLoading] = useState(true)
    const [users,setUsers] = useState([])
    const token = Cookies.get('_auth')
    useEffect(()=>{
        fetch(address+'/users/for/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        }
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setUsers(res)
                setLoading(false)
                console.log(res)
                let obj = {}
                res.map((item)=>obj[`${item.id}`]=false)
                setBackupUsers(res)
            }
        })
    },[])
    const organizationHandler = (e) =>{
        let masiv = []
        masiv = [...backupUsers].filter((item)=>item.organization.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1)
        setUsers(masiv)
    }
    const clearOrganization = () =>{
        document.getElementById('organization').value=''
        setUsers(backupUsers)
    }
    return (
        <div className='admin-finance'>
            <div className='admin-finance-table'>
                <div className='admin-finance-table-title'>
                    <div className='admin-stat-table-title-text'>Пользователи</div>
                </div>
                <div className='admin-finance-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead' >
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th' id='stat-tr-th'><input id='organization' onBlur={()=>document.getElementById('organization').placeholder='Организация'} onFocus={()=>document.getElementById('organization').placeholder=''} type='text' placeholder='Организация' onChange={organizationHandler}/><CloseIcon className='close-icon' title='Очистить поле' sx={{color:'white',fontSize:'14'}} onClick={()=>clearOrganization()}/></th>
                                        <th className='stat-tr-th'>Кратко</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )}
                                    {users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr'>
                                            <td className='stat-tr-td'>{item.organization}</td>
                                            <td className='stat-tr-td'>{item.full_name}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>       
        </div>
    );
}

export default Finance;